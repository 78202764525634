<template>
  <div class="home">
    <div id="nav">
      <!-- 路由跳转连接 -->
      <router-link to="/">Home</router-link> |
      <!-- <router-link to="/about">About</router-link> | -->
      <router-link to="/power">功率</router-link> |
      <router-link to="/kwh">电量</router-link> |
      <router-link to="/zigbee">Zigbee</router-link> |
      <router-link to="/agreement">协议</router-link> |
      <router-link to="/guide">网关引导</router-link> |
      <router-link to="/linking">正在连接</router-link> |
      <router-link to="/linkDevice">连接设备</router-link> |
      <router-link to="/device400">找不到设备</router-link> |
      <router-link to="/serviceInfo">服务信息</router-link> |
      <router-link to="/serviceList">服务列表</router-link> |
      <router-link to="/clickCount">点击事件</router-link> |
      <router-link to="/deviceLog">设备日志</router-link> |
      <router-link to="/masterAutoLog">智能自动化日志</router-link> ｜
      <router-link to="/masterSceneLog">智能场景日志</router-link> ｜
      <router-link to="/upgrade">固件更新</router-link> ｜
      <router-link to="/invent">邀请</router-link> ｜
      <router-link to="/helpInfo">帮助中心</router-link> ｜
      <router-link to="/useAgreement">使用协议</router-link> ｜
      <router-link to="/timeAxisMccgq">门磁传感器</router-link> ｜
      <router-link to="/timeAxisSjcgq">水浸传感器</router-link> ｜
      <router-link to="/timeAxisYwcgq">烟雾传感器</router-link> ｜
      <router-link to="/timeAxisKrqttcq">可燃气体探测器</router-link> ｜
      <router-link to="/timeAxisFreep">随意贴</router-link> ｜
      <button class="btn" @click="selectDate">时间段选择模式</button>
      <calendar @change="onChange" class="calendar-box" :show.sync="show" />
      <!-- <inlineCalendar /> -->

       {{token}}
      <button class="btn" @click="getToken">获取APPToken</button>

      <HelloWorld></HelloWorld>
    </div>
     <button class="btn" @click="showSelect">选择器</button>
     <VuePicker :data="pickData"
      :showToolbar="true"
      @cancel="cancel"
      @confirm="confirm"
      :visible.sync="pickerVisible"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import VuePicker from 'vue-pickers'
var tdata = []
for (let i = 0; i < 20; i++) {
  tdata.push({
    label: '第' + i + '行',
    value: i
  })
}
export default {
  name: "Home",
  data() {
    return {
      calendarShow: true,
      minDate: new Date(), //设置过期时间为当天
      show: false,
      date: "",
      token:"",
      pickerVisible: false,
      pickData: [
        tdata
      ],
      result: ''
    };
  },
  components: {
    HelloWorld,
    VuePicker
  },
  methods: {
    selectDate() {
      this.show = true;
      this.date = "";
    },
    
    onChange(date) {
      console.log(" date " + date);
      // this.date = JSON.stringify(date.map((item) => item.format("YYYY-MM-DD")));
    },
    getToken(){
      this.$bridge.callHandler('getAccessToken',null,(res)=>{
        alert('获取app响应数据:'+res)
        this.token = "Bearer " + res;
      })
    },
    showSelect () {
      this.pickerVisible = true
    },
    cancel () {
      this.result = 'click cancel result: null'
    },
    confirm (res) {
      this.result = JSON.stringify(res)
    }

  },
  created() {
     
  }
 
};
</script>
 