import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import AvueRouter from './avue-router'; //封装的路由控制方法
// import i18n from '@/lang' // Internationalization 国际化 多语言
// import Store from '../store/'; // vuex
// 1.VueRouter是一个插件？
// 内部做了什么：
//    1）实现并声明两个组件router-view  router-link
//    2) install: this.$router.push()
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      i18n: 'dashboard'
    },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      i18n: 'dashboard'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/agreement.vue')
  },
  {
    path: '/power',
    name: 'power',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/power.vue')
  },
  {
    path: '/kwh',
    name: 'kwh',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/kwh.vue')
  },
  {
    path: '/zigbee',
    name: 'zigbee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zigbee.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/guide.vue')
  },
  {
    path: '/linking',
    name: 'linking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/linking.vue')
  },
  {
    path: '/linkDevice',
    name: 'linkDevice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/linkDevice.vue')
  },
  {
    path: '/device400',
    name: 'device400',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/device400.vue')
  }, {
    path: '/serviceInfo',
    name: 'serviceInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/serviceInfo.vue')
  }, {
    path: '/serviceList',
    name: 'serviceList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/serviceList.vue')
  },
  {
    path: '/actionCount',
    name: 'actionCount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/actionCount.vue')
  },
  {
    path: '/deviceLog',
    name: 'deviceLog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/deviceLog.vue')
  },
  {
    path: '/masterSceneLog',
    name: 'masterSceneLog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/masterSceneLog.vue')
  }, {
    path: '/masterAutoLog',
    name: 'masterAutoLog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/masterAutoLog.vue')
  }, {
    path: '/upgrade',
    name: 'upgrade',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/upgrade.vue')
  }, {
    path: '/invent',
    name: 'invent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/invent.vue')
  },
  {
    path: '/helpInfo',
    name: 'helpInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/helpInfo.vue')
  },
  {
    path: '/useAgreement',
    name: 'useAgreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/useAgreement.vue')
  },
  {
    path: '/timeAxisMccgq',
    name: 'timeAxisMccgq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/timeAxisMccgq.vue')
  },
  {
    path: '/timeAxisSjcgq',
    name: 'timeAxisSjcgq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/timeAxisSjcgq.vue')
  },
  {
    path: '/timeAxisYwcgq',
    name: 'timeAxisYwcgq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/timeAxisYwcgq.vue')
  },
  {
    path: '/timeAxisKrqttcq',
    name: 'timeAxisKrqttcq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/timeAxisKrqttcq.vue')
  },
  {
    path: '/timeAxisFreep',
    name: 'timeAxisFreep',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/timeAxisFreep.vue')
  },
  {
    path: '/tmLogin',
    name: 'tmLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tmLogin.vue')
  },
]

 
// 2.创建实例   // mode: 'history',
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// const Router = createRouter() // 获得 route 实例
// AvueRouter.install(Vue, Router, Store, i18n);  // 初始化和注册 AvueRouter

export default router
