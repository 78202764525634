<template>
  <div id="app">
    <div id="nav">
      <!-- 路由跳转连接 -->
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> | -->
      <!-- <router-link to="/Pwoer">功率</router-link> |
      <router-link to="/Kwh">电量</router-link> |
      <router-link to="/actionCount">事件统计</router-link> |
      <router-link to="/Zigbee">Zigbee</router-link> |
      <router-link to="/agreement">协议</router-link> |
      <router-link to="/gateway">网关引导</router-link> |
      <router-link to="/linking">正在连接</router-link> |
      <router-link to="/linkDevice">连接设备</router-link> |
      <router-link to="/Device400">找不到设备</router-link>

      <br>
      <router-link to="/serviceInfo">服务信息</router-link> |
      <router-link to="/serviceList">服务列表</router-link> |
      <router-link to="/upgrade">固件更新</router-link> ｜
      <router-link to="/deviceLog">设备日志</router-link> |
      <router-link to="/masterLog">智能日志</router-link> ｜
      <router-link to="/helpInfo">帮助中心</router-link>
      <br>
      <router-link to="/invent">邀请</router-link> -->
    </div>
    <!-- 设置TOKEN
    <input v-model="token" />
    <button @click="saveToken">保存token</button> -->
    <!-- 路由出口 -->
    <!-- 利用vue响应式：current -->
    <router-view />
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { setToken } from "@/util/auth";
// import { setStore } from "@/util/store.js";
var curComponent;
export default {
  name: "App",
  data: function() {
    return {
      richtext: "App.vue receive msg",
      token: "",
    };
  },
  computed: {
    // ...mapGetters(["permission"]),
  },
  watch: {
    $route(to, from) {
      console.log(" to, from ", to, from);
      console.log(" window.location.href", window.location.href);
      console.log(" this.$route.path ", this.$route.path);
      console.log(" this.$route.params ", this.$route.params);
    },
  },
  methods: {
    setComponent: function(component) {
      curComponent = component;
    },
    saveToken() {
      setToken(this.token);
    },
  },
};
window["receiveMsgFromNative"] = function(msg) {
  curComponent.receiveMsgFromParent(msg);
};
</script>
<style>
body{
  margin: 0px 8px !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-collapse-item__arrow{
  color: #999999 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

/* #nav {
  padding: 30px;
} */

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.m-row:before {
  background-color: #fff !important;
}
.m-calendar .m-toolbar {
  background-color: #fff !important;
}
.m-prev-btn {
  width: 10px !important;
  height: 10px !important;
}
.m-next-btn {
  width: 10px !important;
  height: 10px !important;
}
.m-popover{
  background: rgba(0,0,0,.7) !important;
}
.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
