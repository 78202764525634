import Vue from 'vue'
import axios from "./router/axios";
import VueAxios from "vue-axios";
import App from './App.vue'
import './plugins/element.js'
// import router from './krouter'
import store from './kstore'
import router from './router'
// import i18n from "./lang";

// 引入 ECharts 主模块
let echarts = require("echarts/lib/echarts");
// 引入折线图/柱状图等组件
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/radar");
// 引入提示框和title组件，图例
require("echarts/lib/component/toolbox");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll"); //图例滚动
// 时间轴
require("echarts/lib/component/dataZoom");

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false
// 事件总线
Vue.prototype.$bus = new Vue()

import Bridge from './config/JSbridge.js'
Vue.prototype.$bridge = Bridge

import Calendar from 'vue-mobile-calendar'
Vue.use(Calendar);

Vue.use(VueAxios, axios);

import VueTouch from "vue-touch";
Vue.use(VueTouch, {name: "v-touch"});
// Vue.use(Element, {
//   i18n: (key, value) => i18n.t(key, value)
// });
// Vue.use(window.AVUE, {
//   i18n: (key, value) => i18n.t(key, value)
// });
new Vue({
  // 添加到配置项中
  router,
  store,
 
  render: h => h(App)
}).$mount('#app')
