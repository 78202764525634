import Vue from 'vue'
import Vuex from './kvuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    counter:0
  },
  mutations: {
    add(state) {
      state.counter++;
    },
    setToken(state) {
      state.token;
    }
  },
  actions: {
    add({commit}){
      setTimeout(()=>{
        commit('add')
      },1000)
    },
  },
  modules: {
  },
  getters: {
    doubleCounter(state) {
      return state.counter * 2
    }
  },
  setters: {
    setToken(token) {
      this.state.token = token;
    }
  }
})
