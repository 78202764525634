<template>
  <div>
    <ElementTest></ElementTest>
  </div>
</template>

<script>
  import ElementTest from '@/components/form/ElementTest.vue'
  
  export default {
    components: {
      ElementTest
    },
  }
</script>

<style scoped>

</style>